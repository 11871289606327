import React from 'react';
import IndexView from 'views/IndexView';

const IndexPage = (): JSX.Element => {
  return (
    <>
      <IndexView />
    </>
  );
};

export default IndexPage;
